<template>
  <div class="accountWrap">
    <!-- 表单 -->
    <div class="facilityBox">
      <div style="display: flex;justify-content: space-between;">
        <el-form class="manageForm" :model="manageForm" :inline="true">
          <el-form-item label="企业名称：" prop="EnterpriseFullName">
            <el-input v-model="manageForm.EnterpriseFullName" placeholder="请输入企业名称" @keyup.enter.native="search()">
            </el-input>
          </el-form-item>
          <el-form-item label="日期查询：" prop="TaskDatetime">
            <el-date-picker v-model="manageForm.TaskDatetime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期查询"
              @change="searchChange()"></el-date-picker>
          </el-form-item>
          <el-form-item label-width="10px">
            <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
          </el-form-item>
        </el-form>
        <el-button class="button" style="height:36px;margin:0 10px 0 0;"
          :type="commission.Status == 21 ? 'warning' : 'primary'"
          :disabled="commission.Status == 100 ? false : commission.Status == 21 ? false : true" size="medium"
          @click="() => { dialogVisible = true, dialogName = 'first' }">
          {{
            commission.Status == 100 ? '查看平台结算费率' :
              commission.Status == 21 ? '查看分成' : '平台审核中'
          }}
        </el-button>
      </div>
      <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
        <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="300" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="340" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SetDatetime" label="提交时间" width="130" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CheckUserName" label="审核员姓名" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ServiceUserName" label="客服姓名" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CommissionType" label="分成模式" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CollectTime" label="签订时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="130">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-tickets">查看费率
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper" :total="pagination.total"></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="700px" v-if="dialogVisible" class="rate-dialog">
      <span slot="title">查看分成</span>
      <el-tabs v-model="dialogName">
        <el-tab-pane label="服务商分成" name="first">
          <div class="dialog_box">
            <div class="dialogRole">
              <strong>当前角色：</strong><u>服务商</u><br />
            </div>
            <div class="dialogInfo">
              <div>
                <strong>注册ID：</strong><u>{{ commission.LegalPersonlPhone }} </u><br />
                <strong>企业名称：</strong><u> {{ commission.EnterpriseFullName }}</u><br />
                <strong>身份证号码：</strong><u> {{ commission.LegalPersonIIDCard }}</u><br />
              </div>
              <div>
                <strong>姓名： </strong><u>{{ commission.LegalPersonIName }} </u><br />
                <strong>电话：</strong><u>{{ commission.LegalPersonlPhone }} </u><br />
                <strong>信用代码：</strong><u> {{ commission.EnterpriseCreditCode }}</u><br />
              </div>
            </div>
            <el-table :data="ratelist" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading"
              max-height="500px">
              <el-table-column align="center" label="发票-业务-场景">
                <template slot-scope="scope">
                  <div v-if="scope.row.TaskSceneName">
                    <span v-if="scope.row.TaskSceneTypeID == 3">
                      {{ scope.row.InvoiceTypeName[1] }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                    <span v-else-if="scope.row.TaskSceneTypeID == 31">
                      {{ scope.row.InvoiceTypeName[0] }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                    <span v-else>
                      {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                  </div>
                  <div v-else>
                    <span v-if="scope.row.TaskSceneTypeID == 3">
                      {{ scope.row.InvoiceTypeName[1] }}</span>
                    <span v-else-if="scope.row.TaskSceneTypeID == 31">
                      {{ scope.row.InvoiceTypeName[0] }}</span>
                    <span v-else>
                      {{ scope.row.InvoiceTypeName }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="center" label="数值" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.TaskSceneTypeID == 3">{{ scope.row.PP_Rate }}</span>
                  <span v-else>{{ scope.row.Rate }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="代理商分成" name="second">
          <div class="dialog_box">
            <div class="dialogRole">
              <strong>当前角色：</strong><u>代理商</u><br />
            </div>
            <div class="dialogInfo">
              <div>
                <strong>注册ID：</strong><u>{{ commission.LegalPersonlPhone }} </u><br />
                <strong>企业名称：</strong><u> {{ commission.EnterpriseFullName }}</u><br />
                <strong>身份证号码：</strong><u> {{ commission.LegalPersonIIDCard }}</u><br />
              </div>
              <div>
                <strong>姓名： </strong><u>{{ commission.LegalPersonIName }} </u><br />
                <strong>电话：</strong><u>{{ commission.LegalPersonlPhone }} </u><br />
                <strong>信用代码：</strong><u> {{ commission.EnterpriseCreditCode }}</u><br />
              </div>
            </div>
            <el-table :data="ratelistAgent"
              :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading" max-height="500px">
              <el-table-column align="center" label="发票-业务-场景">
                <template slot-scope="scope">
                  <div v-if="scope.row.TaskSceneName">
                    <span v-if="scope.row.TaskSceneTypeID == 3">
                      {{ scope.row.InvoiceTypeName[1] }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                    <span v-else-if="scope.row.TaskSceneTypeID == 31">
                      {{ scope.row.InvoiceTypeName[0] }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                    <span v-else>
                      {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                  </div>
                  <div v-else>
                    <span v-if="scope.row.TaskSceneTypeID == 3">
                      {{ scope.row.InvoiceTypeName[1] }}</span>
                    <span v-else-if="scope.row.TaskSceneTypeID == 31">
                      {{ scope.row.InvoiceTypeName[0] }}</span>
                    <span v-else>
                      {{ scope.row.InvoiceTypeName }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="center" label="数值" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.TaskSceneTypeID == 3">{{ scope.row.PP_AgentRate }}</span>
                  <span v-else>{{ scope.row.AgentRate }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" :disabled="commission.Status == 100 ? true : false"
          @click="agreeClick()">
          正式合作</el-button>
      </span>
    </el-dialog>
    <!-- 查看费率 -->
    <el-dialog :visible.sync="rateMdel" width="720px" v-if="rateMdel" class="rate-dialog">
      <span slot="title">查看费率</span>
      <el-tabs v-model="dialogName">
        <el-tab-pane label="服务商费率" name="first">
          <div class="dialog_box">
            <div class="dialogRole" style="margin-bottom: 16px;">
              <strong>企业名称：</strong><u>{{ serveInfo.EnterpriseFullName }}</u><br />
            </div>
            <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
              v-loading="loading">
              <el-table-column align="center" label="发票-业务-场景">
                <template slot-scope="scope">
                  <div v-if="scope.row.TaskSceneName">
                    <span v-if="scope.row.TaskSceneTypeID == 3">
                      {{ scope.row.InvoiceTypeName[1] }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                    <span v-else-if="scope.row.TaskSceneTypeID == 31">
                      {{ scope.row.InvoiceTypeName[0] }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                    <span v-else>
                      {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                  </div>
                  <div v-else>
                    <span v-if="scope.row.TaskSceneTypeID == 3">
                      {{ scope.row.InvoiceTypeName[1] }}</span>
                    <span v-else-if="scope.row.TaskSceneTypeID == 31">
                      {{ scope.row.InvoiceTypeName[0] }}</span>
                    <span v-else>
                      {{ scope.row.InvoiceTypeName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="数值" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.TaskSceneTypeID == 3">{{ scope.row.PP_Rate }}</span>
                  <span v-else>{{ scope.row.Rate }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="代理商费率" name="second">
          <div class="dialog_box">
            <div class="dialogRole" style="margin-bottom: 16px;">
              <strong>企业名称：</strong><u>{{ serveInfo.EnterpriseFullName }}</u><br />
            </div>
            <el-table :data="rateData.filter((i, idx) => idx > 1)"
              :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
              <el-table-column align="center" label="发票-业务-场景">
                <template slot-scope="scope">
                  <div v-if="scope.row.TaskSceneName">
                    <span v-if="scope.row.TaskSceneTypeID == 3">
                      {{ scope.row.InvoiceTypeName[1] }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                    <span v-else-if="scope.row.TaskSceneTypeID == 31">
                      {{ scope.row.InvoiceTypeName[0] }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                    <span v-else>
                      {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                        scope.row.TaskSceneName
                      }}</span>
                  </div>
                  <div v-else>
                    <span v-if="scope.row.TaskSceneTypeID == 3">
                      {{ scope.row.InvoiceTypeName[1] }}</span>
                    <span v-else-if="scope.row.TaskSceneTypeID == 31">
                      {{ scope.row.InvoiceTypeName[0] }}</span>
                    <span v-else>
                      {{ scope.row.InvoiceTypeName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="数值" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.TaskSceneTypeID == 3">{{ scope.row.PP_AgentRate }}</span>
                  <span v-else>{{ scope.row.AgentRate }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRecordList,
  commissionInfo,
  AgreeCommission,
  getRecordDetail
} from "@/api/finance/account/index";
export default {
  data() {
    return {
      // 查看费率弹窗
      rateMdel: false,
      // 已选中分成模式
      activeName: "",
      dialogName: 'first',
      // 是否显示分成弹窗
      dialogVisible: false,
      // 筛选条件数据
      manageForm: {
        TaskDatetime: "",
        EnterpriseFullName: ''
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 服务商-平台历史分成汇总
      invoices: "",
      // 审核状态
      commission: "",
      // 直接分成
      directDisabled: true,
      // 保底分成
      disabled: true,
      // 表格load
      loading: true,
      // 费率数据
      rateData: [],
      // 服务商信息
      serveInfo: '',
      ratelist: '',
      ratelistAgent: []
    };
  },
  methods: {
    searchChange() {
      this.search()
    },
    // 确定合作
    agreeClick() {
      AgreeCommission().then((res) => {
        this.dialogVisible = false;
        this.supplierType()
      });
    },
    // 清空搜索
    resetForm() {
      this.loading = true;
      this.pagination.page = 1;
      this.manageForm = {
        EnterpriseFullName: "",
        TaskDatetime: "",
      };
      this.getRecordList();
    },
    // 条件搜索
    search() {
      this.loading = true;
      this.pagination.page = 1;
      this.getRecordList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      // 获取开票相关信息
      this.getRecordList();
    },
    //查看结算单详情 
    goFacility(item) {
      this.rateMdel = true;
      this.serveInfo = item
      this.dialogName = 'first'
      getRecordDetail({ CID: item.CID }).then((res) => {
        let ratelist = [
          {
            InvoiceTypeName: '平台技术服务费费率',
            Rate: `${res.recordList[0].Rate}%`
          }, {
            InvoiceTypeName: '每月可开票最大金额',
            Rate: res.recordList[0].TotalInvoiceAmount
          }
        ]
        res.recordList.forEach(item => {
          if (item.InvoiceTypeName.indexOf('/') != '-1') {
            item.InvoiceTypeName = item.InvoiceTypeName.split("/")
          }
          item.Rate = `${item.RateMinimun}%`
          item.PP_Rate = `${item.PP_RateMinimun}%`
          item.AgentRate = `${item.AgentRate}%`
          item.PP_AgentRate = `${item.PP_AgentRate}%`
          if (item.TaskSceneTypeID == 3) {
            ratelist.push(item, {
              ...item,
              TaskSceneTypeID: 31
            })
          } else {
            ratelist.push(item)
          }
        })
        this.rateData = ratelist
      })
    },
    // 服务商查看平台设置比例历史记录
    getRecordList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        SubmitDatetimeStart: this.manageForm.TaskDatetime,
        EnterpriseFullName: this.manageForm.EnterpriseFullName
      };
      getRecordList({ Json: JSON.stringify(params) }).then((res) => {
        this.tableData = res.recordList;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    supplierType() {
      commissionInfo({ Json: "" }).then((res) => {
        if (res.commission) {
          let ratelist = [
            {
              InvoiceTypeName: '平台技术服务费费率',
              Rate: `${res.commission.invoiceTypeLists[0].Rate}%`
            }, {
              InvoiceTypeName: '每月可开票最大金额',
              Rate: res.commission.invoiceTypeLists[0].TotalInvoiceAmount
            }
          ]
          res.commission.invoiceTypeLists.forEach(item => {
            if (item.InvoiceTypeName.indexOf('/') != '-1') {
              item.InvoiceTypeName = item.InvoiceTypeName.split("/")
            }
            item.Rate = `${item.RateMinimun}%`
            item.PP_Rate = `${item.PP_RateMinimun}%`
            item.AgentRate = `${item.AgentRate}%`
            item.PP_AgentRate = `${item.PP_AgentRate}%`
            if (item.TaskSceneTypeID == 3) {
              ratelist.push(item, {
                ...item,
                TaskSceneTypeID: 31
              })
            } else {
              ratelist.push(item)
            }
          })
          this.ratelist = ratelist
          this.commission = res.commission;
          let ratelistAgent = []
          res.commission.AgentInvoiceTypeLists.forEach(item => {
            if (item.InvoiceTypeName.indexOf('/') != '-1') {
              item.InvoiceTypeName = item.InvoiceTypeName.split("/")
            }
            item.AgentRate = `${item.AgentRate}%`
            item.PP_AgentRate = `${item.PP_AgentRate}%`
            if (item.TaskSceneTypeID == 3) {
              ratelistAgent.push(item, {
                ...item,
                TaskSceneTypeID: 31
              })
            } else {
              ratelistAgent.push(item)
            }
          })
          this.ratelistAgent = ratelistAgent
        }
      });
    }
  },
  created() {
    // 汇总开票相关信息
    // taskSummary(1).then((res) => {
    //   this.invoices = res.invoices[0];
    // });
    // 获取开票相关信息
    this.getRecordList();
    // 获取服务商审核状态
    this.supplierType()
  },
};
</script>
<style lang="scss">
.rate-dialog {
  .el-dialog__body {
    padding-top: 0px;
  }
}
</style>
<style lang="scss" scoped>
.accountWrap {
  .dialog_box {
    font-size: 13px;
    line-height: 26px;
    width: 100%;
    background: #f8f8f8;
    padding: 20px 5%;
    box-sizing: border-box;
    color: #666;
    border-radius: 12px;

    .dialogRole {
      width: 100%;
      text-align: center;
    }

    .dialogInfo {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }

  .mdoeBox {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    line-height: 80px;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    color: #000;
  }

  .mdoeDiv {
    width: 80%;
    margin: 10px 0 0;
    line-height: 26px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10%;
    color: #333;

    span {
      color: #ff2323;
      font-size: 16px;
    }
  }

  .facilityBox {

    // margin: 20px 0 0 0;
    .settleBox {
      margin-bottom: 10px;
      padding: 10px 16px;
      background-color: #f0f0f0;
      color: #666;
    }

    .topbtns {
      padding-bottom: 10px;
      margin: 0px 0px 10px;
      border-bottom: 1px solid #eee;
    }
  }

  .accountTitle {
    margin: 20px 0 0 0;
    font-size: 24px;
    padding: 0 32%;
    text-align: center;
    position: relative;

    .button {
      position: absolute;
      bottom: 10px;
      right: 0px;
    }

    .accountInfo {
      width: 30vw;
      background: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      margin: 20px 0 0 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
}
</style>